import React from 'react'

const Error = () => {
    return (
        <>
            <h1>Error 404</h1>
        </>
    )
} 

export default Error
